/* eslint-disable no-console */
const version = 'v4';
import { register } from 'register-service-worker'
if (process.env.NODE_ENV === 'production' && localStorage["deviceType"] !== '3'&& 0) {
  register(`${process.env.BASE_URL}service-worker.js?v=${version}`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker registered.')
    },
    cached () {
      // 已缓存内容以供离线使用。
      console.log('The content is cached for offline use.')
    },
    updatefound () {
      // 新内容正在下载。
      console.log('New content is downloading.')
    },
    updated (registration) {
      const newWorker = registration.waiting;
      if (newWorker) {
        newWorker.postMessage({ type: 'SKIP_WAITING' });
      }
      console.log('New service worker installed.');
      setTimeout(() => {
        window.location.reload()
      },5000)
    },
    offline () {
      // console.log('没有找到互联网连接。应用程序以离线模式运行')
    },
    error (error) {
      console.error('service worker error:', error)
    },
  });
}
